import React from 'react';

export default function UserGuideForm() {
  return (
    <div
      className="mx-auto mb-12"
      style={{ marginLeft: 'auto', marginRight: 'auto', marginBottom: '3rem' }}>
      <iframe
        style={{ width: '100%', height: '100vh', border: 0 }}
        title="linkuserguide apr"
        src="https://view.monday.com/3995300148-7e1b1b55c0f8b287bb96b1204f209270?r=use1"></iframe>
    </div>
  );
}
