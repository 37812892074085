import Intercom from '@intercom/messenger-js-sdk';
import mondaySdk from 'monday-sdk-js';
import 'monday-ui-react-core/dist/main.css';
import React from 'react';
import 'react-dropdown/style.css';
import './App.css';
import MainForm from './MainForm';
import UserGuideForm from './UserGuide';
import OperationCounterForm from './OperationCounterForm';
import BillingForm from './BillingForm';
import { Tab, TabList } from 'monday-ui-react-core';

const Backend = require('./Backend');
const monday = mondaySdk();

class App extends React.Component {
  constructor(props) {
    super(props);

    Intercom({
      app_id: 'efnew8bu',
      alignment: 'left',
      horizontal_padding: 100,
      vertical_padding: 40,
    });

    // Default state
    this.state = {
      context: {},
      linkOC: '',
      currentTab: 0, // Add currentTab to state
    };
  }

  async componentDidMount() {
    monday.listen('context', this.getContext);
  }

  getContext = async (res) => {
    const context = res.data;
    const linkOC = await Backend.getOperationCounterLink();
    this.setState({ linkOC: linkOC }); // Use setState
    this.setState({ context, boardId: context.boardId }, function () {});
  };

  changeTab = (tabId) => {
    this.setState({ currentTab: tabId });
  };

  render() {
    const { currentTab, linkOC } = this.state; // Destructure currentTab from state

    return (
      <div style={{ width: '80%', margin: 'auto' }}>
        <TabList
          tabType="stretched"
          onTabChange={(tabId) => this.changeTab(tabId)}>
          <Tab>Aproval & Feedback</Tab>
          <Tab>User Guide</Tab>
          <Tab>Operation Counter</Tab>
          <Tab>Billing</Tab>
        </TabList>
        <div className="mt-6">
          {currentTab === 0 && <MainForm />}
          {currentTab === 1 && <UserGuideForm />}
          {currentTab === 2 && <OperationCounterForm linkOC={linkOC} />}
          {currentTab === 3 && <BillingForm />}
        </div>
      </div>
    );
  }
}

export default App;
