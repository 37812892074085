import { Button } from 'monday-ui-react-core';
import React from 'react';
import mondaySdk from 'monday-sdk-js';

const monday = mondaySdk();

const BillingForm = () => {
  async function handleBilling() {
    monday.execute('openPlanSelection', {
      isInPlanSelection: true,
      selectedPlanId: '123456',
    });
  }

  return (
    <div
      className="mx-auto mb-12"
      style={{ marginLeft: 'auto', marginRight: 'auto', width: '80%' }}>
      <Button onClick={handleBilling}>Manage Billing</Button>
    </div>
  );
};

export default BillingForm;
